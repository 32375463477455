.intercom-lightweight-app {
  z-index: 10 !important;
}

/* Classes for fix the overlap intercom button with open modal*/

#intercom-frame + .intercom-lightweight-app {
  z-index: 10 !important;
}
#intercom-container iframe.intercom-launcher-frame {
  z-index: 10 !important;
}
#intercom-container iframe[name='intercom-notifications-frame'] {
  z-index: 10 !important;
}
#intercom-container iframe.intercom-launcher-badge-frame {
  z-index: 10 !important;
  width: '23px';
}
#intercom-container iframe#launcher-badge-container {
  z-index: 10 !important;
}
