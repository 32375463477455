.DayPickerKeyboardShortcuts_show {
  display: none;
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover,
.DayPickerNavigation_button__default:active,
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: none;
  background-color: transparent;
}

.DayPicker {
  margin-left: auto;
  margin-right: auto;
}

.CalendarMonth_caption {
  color: #596066;
  font-family: var(--chakra-fonts-body);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
}

.DayPicker_weekHeader {
  color: #939393;
  font-family: var(--chakra-fonts-body);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  top: 58px;
}

.CalendarDay__default {
  font-family: var(--chakra-fonts-body);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  color: #596066;
  border: none;
  position: relative;
  z-index: 1;
}

.CalendarDay__default:hover {
  border: none;
  color: #596066;
  background: transparent;
}

.CalendarDay__outside,
.CalendarDay__blocked_out_of_range {
  color: #939393;
}

/* reset airbnb grid styles here (color of square, using pseudo elements for styling) */
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__selected_span,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background-color: transparent;
  position: relative;
  z-index: 1;
  border: inherit;
  color: inherit;
}

.CalendarDay__selected,
.CalendarDay__selected:hover {
  color: white;
}

/* :after is used for the green outline for hovering here */
.CalendarDay__default:hover:after {
  border-radius: 50px;
  border: solid 1px var(--chakra-colors-secondary-500);
  background-color: transparent;
  display: block;
  content: '';
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  z-index: -1;
}

/* :after is used for the green dot everywhere else */
.CalendarDay__hovered_span:hover:after {
  background-color: var(--chakra-colors-secondary-500);
}

.CalendarDay__selected:active:after,
.CalendarDay__selected:hover:after,
.CalendarDay__selected:after {
  border-radius: 50px;
  background-color: var(--chakra-colors-secondary-500);
  display: block;
  content: '';
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  z-index: -1;
}

/* :before is used for the Highlighted green bar */

/* sets full green square background */
.CalendarDay__selected_span:before,
.CalendarDay__hovered_span:before,
.CalendarDay__selected:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 7px;
  bottom: 7px;
  background-color: var(--chakra-colors-secondary-50);
  z-index: -1;
}

/* set green square on left (end of selection) */
:not(.CalendarDay__no_selected_start_before_selected_end).CalendarDay__hovered_span:hover:before,
.CalendarDay__selected_end:before {
  right: 50%;
}

/* set green square on right (start of selection when end already selected) */
.CalendarDay__no_selected_start_before_selected_end:hover:before {
  left: 50%;
}

/* set green square on right (start of selection) */
.CalendarDay__selected_start:before {
  left: 50%;
}

/* BUG IN REACT-DATES! https://github.com/airbnb/react-dates/issues/1945 */
/* reset airbnb styling that shows next day selected when selecting start date */
/* .CalendarDay__after_hovered_start:before {
  display: none;
} */

/* BUG IN REACT-DATES! https://github.com/airbnb/react-dates/issues/1945 */
/* hide green block when only start date in span and nothing else hovered */
/* :not(.CalendarDay__selected_start_in_hovered_span).CalendarDay__selected_start_no_selected_end:before {
  display: none;
} */

/* reset airbnb styling that previous next day selected when selecting end date */
:not(.CalendarDay__selected_span).CalendarDay__before_hovered_end:before {
  display: none;
}

/* hide green block when only end date in span and nothing else hovered */
:not(.CalendarDay__selected_end_in_hovered_span).CalendarDay__selected_end_no_selected_start:before {
  display: none;
}

:not(.CalendarDay__selected_span).CalendarDay__after_hovered_start::before {
  display: none;
}

/* Highlighted green bar */

.DayPickerNavigation_button {
  border: none;
}
.DayPickerNavigation_svg__horizontal {
  fill: var(--chakra-colors-secondary-500);
}
.DayPickerNavigation_svg__disabled {
  fill: #596066;
}

/* TODO - decide at which breakpoint should go mobile */
@media (max-width: 600px) {
  .DayPicker__horizontal {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
  }

  .DayPicker_weekHeader {
    top: 0;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
  }

  .CalendarMonth_caption {
    padding-top: 65px;
    padding-bottom: 9px;
  }

  .DayPickerNavigation_button__horizontalDefault {
    top: 58px;
  }
}
